.text-accent-blue {
  color: #004E7C;
}

.text-accent-red {
  color: #B73225;
}

.card-link {
  width: 100%;
  height: 100%;
}

.login-icon {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.login-icon:hover {
  background-color: #f1f1f1;
  -webkit-transition-timing-function: ease;
          transition-timing-function: ease;
}

.link-plain {
  text-decoration: none;
}

.sessions-preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.text-underline {
  text-decoration: underline;
}

.text-underline:hover {
  cursor: pointer;
}

.uncenter-content {
  text-align: start;
}

.dev-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  background-color: aquamarine;
  overflow-x: scroll;
  word-wrap: break-word;
}

.large-icon {
  width: 50px;
  height: 50px;
}

.hover-cursor:hover {
  cursor: pointer;
  border-color: grey;
}

.small-form-group {
  width: 50vw;
  margin-right: auto;
  margin-left: auto;
}

.small-form-input {
  width: 10px;
}

.center-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.center-list {
  text-align: center;
  list-style-position: inside;
}

.auth-card {
  width: 80vw;
}

.undecorated-list {
  list-style: none;
}

.landingpage-container {
  background: #eceff0;
  background: linear-gradient(120deg, #eceff0 14%, #dce1e3 100%);
  color: #5C5F58;
  padding-top: 100px;
}

.aboutpage-container {
  background: #eceff0;
  background: linear-gradient(120deg, #eceff0 14%, #dce1e3 100%);
  color: #5C5F58;
  padding-top: 50px;
}

.home-container {
  color: #5C5F58;
}
