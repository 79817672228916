$oauth-icon-width: 60px;
$oauth-icon-height: 60px;

$background-light: #DCE1E3;
$background-lighter: #e9edee;
$text-dark: #5C5F58;
$text-accent-blue: #004E7C;
$text-accent-red: #B73225;

.text-accent-blue {
  color: $text-accent-blue;
}
.text-accent-red {
  color: $text-accent-red;
}

.header-container {
  // background-color: $background-lighter;
  // color: $text-dark;
}

.card-link {
  width: 100%;
  height: 100%;
}

.login-icon {
  width: $oauth-icon-width;
  height: $oauth-icon-height;
  border-radius: 100%;

  &:hover {
    background-color: rgb(241, 241, 241);
    transition-timing-function: ease;
  }
}

.link-plain {
  text-decoration: none;
}

.sessions-preview {
  display: flex;
  flex-wrap: wrap;
}

.text-underline {
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}
.uncenter-content {
  text-align: start;
}

.dev-info {
  display: flex;
  flex-wrap: wrap;
  background-color:aquamarine;
  overflow-x: scroll;
  word-wrap: break-word;
}

.large-icon {
  width: 50px;
  height: 50px;
}

.hover-cursor {
  &:hover {
    cursor: pointer;
    border-color: grey;
  }
}

.small-form-group {
  width: 50vw;
  margin-right: auto;
  margin-left: auto;
}
.small-form-input {
  width: 10px;
}

.center-container {
  display: flex;
  justify-content: center;
}

.center-list {
  text-align: center;
  list-style-position: inside;
}

.auth-card {
  width: 80vw;
}

.undecorated-list {
  list-style: none;
}

.landingpage-container {
  background: rgb(236,239,240);
  background: linear-gradient(120deg, rgba(236,239,240,1) 14%, rgba(220,225,227,1) 100%);
  color: $text-dark;
  padding-top: 100px;
}

.aboutpage-container {
  background: rgb(236,239,240);
  background: linear-gradient(120deg, rgba(236,239,240,1) 14%, rgba(220,225,227,1) 100%);
  color: $text-dark;
  padding-top: 50px;
}

.home-container {
  color: $text-dark;
}